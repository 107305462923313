@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!

.cust-navbar {
    border-bottom: 2px solid rgba(74, 80, 115, 0.5);
}

input.table-search {
  width: 30%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #14877c;
  padding: 10px;
  font-size: 14px;
}
  
table {
  margin: 20px 0;
}

thead {
  font-size: 16px;
}

th {
  text-align: left;
}
td {
  text-align: left;
}

.error-message {
  text-align: center;
  margin-top: 10%;
  color: red;
  font-weight: bold;
}

.loading {
  text-align: center;
  margin-top: 10%;
}

.cust-button {
  background-color: #F6E80A;
  font-size: 16px;
  color: #000;
}
